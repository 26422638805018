<template>
  <b-tabs pills>
    <b-tab title="Mitra UMKM" active>
      <b-card-text>
        <umkm></umkm>
      </b-card-text>
    </b-tab>
    <b-tab title="Rincian Peminjaman">
      <b-card-text>
        Pudding candy canes sugar plum cookie chocolate cake powder croissant. Carrot cake tiramisu danish candy cake
        muffin croissant tart dessert. Tiramisu caramels candy canes chocolate cake sweet roll liquorice icing
        cupcake.Bear claw chocolate chocolate cake jelly-o pudding lemon drops sweet roll sweet candy. Chocolate sweet
        chocolate bar candy chocolate bar chupa chups gummi bears lemon drops.
      </b-card-text>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import Umkm from './Umkm.vue'

export default {
  components: {
    BTab,
    BTabs,
    Umkm,
  },
}
</script>